import React, { Fragment } from "react"
import { graphql } from "gatsby"
import SiteLayout from "../components/SiteLayout"
import CategoriesWidget from "../components/CategoriesWidget"
import RecentPostsWidget from "../components/RecentPostsWidget"
import PostEntryMeta from "../components/PostEntryMeta"
import Seo from "../components/Seo"

const renderTermNodes = (nodes, title) => (
  <div>
    {title}
    {` `}
    {nodes.map(term => (
      <div key={term.name}>{term.name}</div>
    ))}
  </div>
)

const renderTerms = (categoryNodes = [], tagNodes = []) => (
  <Fragment>
    {categoryNodes ? renderTermNodes(categoryNodes, `Categories: `) : null}
    {tagNodes && tagNodes.length ? renderTermNodes(tagNodes, `Tags: `) : null }
  </Fragment>
)

const Post = props => {
  const {
    location,
    data: {
      allWpPost
    },
  } = props
  const { title, content } = allWpPost.edges[0].node
  return (
    <SiteLayout location={location}>
      <Seo title={`${title}`} />
      <h1>{title}</h1>
      <PostEntryMeta post={allWpPost.edges[0].node} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <RecentPostsWidget />
      <CategoriesWidget />
    </SiteLayout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: String!) {
    allWpPost(filter: {id: {eq: $id}}) {
      edges {
        node {
          title
          content
          uri
          author {
            node {
              name
              slug
              avatar {
                url
              }
            }
          }
          tags {
            nodes {
              name
              link
            }
          }
        }
      }
    }
  }
`
